import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import {
  NormalizedUsersApiResponse,
  UserApiFieldsWithoutPII,
  UsersActionTypes,
  usersService,
} from 'app/fieldapp/store/users'

export const searchUsersAction = createAsyncAction(
  UsersActionTypes.SEARCH_USERS,
  UsersActionTypes.SEARCH_USERS_SUCCESS,
  UsersActionTypes.SEARCH_USERS_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (search: string) => ({
  fields: {
    housekeeper: [],
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    eligible_for_search: true,
    is_active: true,
    search,
  },
  include: ['housekeeper'],
  page: { size: 25 },
})

export const searchUsers =
  (search: string, authToken?: string | undefined) => async dispatch => {
    try {
      const params = getParams(search)
      const request = usersService.fetchUsers.bind(null, params, authToken)
      const result = await dispatch(searchUsersAction.request({ request }))
      dispatch(searchUsersAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(searchUsersAction.failure(error))
      throw error
    }
  }
