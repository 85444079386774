import { AxiosError } from 'axios'

import { api } from '../ticketingAxiosInstance'
import { RawTicketAttachment } from './attachments.types'

const getAttachmentsUrl = (ticketId: string) =>
  `/v1/tickets/${ticketId}/attachments`

export const attachmentsService = {
  async uploadAttachments(
    formData: FormData,
    ticketId: string,
    authToken: string,
  ): Promise<{ error?: string; success: boolean }> {
    try {
      await api.post(getAttachmentsUrl(ticketId), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + authToken,
        },
      })

      return { success: true }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 413) {
          return { success: false, error: 'Uploaded file is too large' }
        } else {
          return { success: false, error: 'Failed to upload attachments' }
        }
      }

      return {
        success: false,
        error: 'Unknown error while uploading attachments',
      }
    }
  },
  async getAttachments(
    ticketId: string,
    authToken: string,
  ): Promise<{
    data: RawTicketAttachment[]
    error?: string
    success: boolean
  }> {
    try {
      const response = await api.get(getAttachmentsUrl(ticketId), {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })

      return { success: true, data: response.data.raw.data }
    } catch (error) {
      return {
        data: [],
        success: false,
        error:
          'Failed to fetch attachments. Please try reload the application.',
      }
    }
  },
  async deleteAttachment(
    ticketId: string,
    filename: string,
    authToken: string,
  ): Promise<{ error?: string; success: boolean }> {
    try {
      await api.delete(
        `${getAttachmentsUrl(ticketId)}?attachmentNames=${filename}`,
        {
          headers: {
            Authorization: 'Bearer ' + authToken,
          },
        },
      )

      return { success: true }
    } catch (error) {
      return {
        success: false,
        error: 'Failed to delete attachment. Please try again.',
      }
    }
  },
}
