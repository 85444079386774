import { logError } from 'packages/wiretap/src/logging/logger'

import { hydrateRawUser } from 'app/fieldapp/store/users'
import { searchUsers } from 'app/fieldapp/store/users/actions/searchUsers'
import { RawUser, User } from 'app/fieldapp/store/users/users.types'

export const loadOptions = async (
  dispatch: (arg: unknown) => void,
  authCodeForUserSearch: string | undefined,
  input: string,
  callback: (users: User[]) => void,
): Promise<void> => {
  if (input.length >= 3) {
    try {
      const normalizedResult = (await dispatch(
        searchUsers(input, authCodeForUserSearch ?? undefined),
      )) as unknown as { user: RawUser[] }
      const usersArray = Object.values(normalizedResult.user) as RawUser[]
      const users = usersArray.map(hydrateRawUser)
      callback(users)
    } catch (error) {
      logError(error)
      callback([])
    }
  } else {
    callback([])
  }
}
