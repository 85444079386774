import { useDecision } from '@optimizely/react-sdk'
import { debounce } from 'lodash'
import React from 'react'
import { usePrevious } from 'react-use'

import { Features } from 'packages/optimizely'
import {
  useAsyncFnWithReset,
  useMemoById,
  useOnlineStatus,
} from 'packages/utils/hooks'

import { getFanOutBatchRequests } from 'app/fieldapp/store/app/selectors'
import {
  deleteCleanTime,
  silentDeleteCleanTime,
} from 'app/fieldapp/store/cleantimes/actions'
import { getActiveCleanTime } from 'app/fieldapp/store/cleantimes/selectors'
import { useAppDispatch, useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getTasksByAssignmentIds } from 'app/fieldapp/store/tasks/selectors'

import { useOfflineMergedTimers } from '../useOfflineMergedTimers'
/**
 * Removes all clean timers with no associated clean after we fetch all clean timer data.
 * This ensures that timers for any clean that has been unassigned from the active user don't cause a UI crash.
 */
export const usePurgeUnlinkedTimers = (): void => {
  const dispatch = useAppDispatch()

  const activeCleanTime = useAppSelector(getActiveCleanTime)
  const fanOutBatchRequestsState = useAppSelector(getFanOutBatchRequests)
  const memoizedActiveCleanTime = useMemoById(activeCleanTime)
  const prevMemoizedActiveCleanTime = usePrevious(memoizedActiveCleanTime)
  const activeTimerHasChanged =
    memoizedActiveCleanTime?.id !== prevMemoizedActiveCleanTime?.id

  const { offlineTimersCount, offlineTimers } = useOfflineMergedTimers()
  const isOnline = useOnlineStatus().isOnline()

  const assignmentIds = [
    ...offlineTimers.cleanTimes.map(timer => timer.assignment.id),
    activeCleanTime?.assignment?.id,
  ].filter(Boolean) as string[]

  const activeTasksForUser = useAppSelector((state: ApplicationState) =>
    getTasksByAssignmentIds(state, assignmentIds),
  )

  const debouncedDispatchRef = React.useRef<ReturnType<typeof debounce> | null>(
    null,
  )
  const [decision] = useDecision(Features.TIMER_PURGE_DEBOUNCE)
  const isFeatureEnabled = React.useMemo(
    () => decision.enabled,
    [decision.enabled],
  )
  const purgeUnlinkedCleanTimer = React.useCallback(() => {
    // 2. If feature is enabled AND we haven't created a debounced function yet
    if (isFeatureEnabled && !debouncedDispatchRef.current) {
      // Create a debounced version of the delete dispatch
      debouncedDispatchRef.current = debounce((timerId: string) => {
        dispatch(deleteCleanTime(timerId, true)) // true indicates debounced
      }, 1000)
    }

    // 3. For each timer that needs to be deleted:
    offlineTimers.cleanTimes.forEach(timer => {
      if (!activeTasksForUser[timer.assignment.id]) {
        if (debouncedDispatchRef.current) {
          // If debouncing is enabled, use the debounced version
          debouncedDispatchRef.current(timer.id)
        } else {
          // If debouncing is disabled, delete immediately
          dispatch(deleteCleanTime(timer.id, false)) // false indicates non-debounced
        }
      }
    })
  }, [activeTasksForUser, offlineTimers, dispatch, isFeatureEnabled])

  React.useEffect(() => {
    if (
      offlineTimersCount &&
      fanOutBatchRequestsState === 'fulfilled' &&
      isOnline
    ) {
      purgeUnlinkedCleanTimer()
    }

    return () => {
      debouncedDispatchRef.current?.cancel()
    }
  }, [
    fanOutBatchRequestsState,
    isOnline,
    offlineTimersCount,
    purgeUnlinkedCleanTimer,
  ])

  //------------------------------------------------
  // Dealing with "regular" online timers
  //------------------------------------------------
  const [deleteCleanTimeState, deleteCleanTimeFn, resetDeleteCleanTime] =
    useAsyncFnWithReset(
      async (cleanTimeId: string) => {
        await dispatch(silentDeleteCleanTime(cleanTimeId))
      },
      [dispatch],
    )

  // if the active timer changes, we need to reset our request state to ensure we clear errors
  // this prevents ANOTHER edge case where a user could potentially have multiple subsequent
  // "unlinked" timers in a single session, which would cause the Timers UI to get stuck in disabled state
  React.useEffect(() => {
    if (activeTimerHasChanged) resetDeleteCleanTime()
  }, [activeTimerHasChanged, resetDeleteCleanTime])

  // also delete any regular online timer that is associated with a clean no longer assigned to this user
  React.useEffect(() => {
    if (!memoizedActiveCleanTime || memoizedActiveCleanTime.startedOffline) {
      return
    }

    const { error, loading } = deleteCleanTimeState
    if (error || loading) return // avoid multiple requests for the same timer

    const timerHasNoTask =
      !activeTasksForUser[memoizedActiveCleanTime.assignment?.id]

    if (timerHasNoTask) {
      deleteCleanTimeFn(memoizedActiveCleanTime.id)
    }
  }, [
    activeTasksForUser,
    deleteCleanTimeState,
    deleteCleanTimeFn,
    dispatch,
    memoizedActiveCleanTime,
  ])
}
