import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store'

import { offlineTimers } from '../../utils'
import { cleanTimesService } from '../cleantimes.service'
import {
  CleanTimesActionTypes,
  NormalizedCleanTimesApiResponse,
} from '../cleantimes.types'
import { getMergedCleanTimeById } from '../selectors'

export const deleteCleanTimeAction = createAsyncAction(
  CleanTimesActionTypes.DELETE_CLEAN_TIME,
  CleanTimesActionTypes.DELETE_CLEAN_TIME_SUCCESS,
  CleanTimesActionTypes.DELETE_CLEAN_TIME_FAILURE,
)<RequestConfig<NormalizedCleanTimesApiResponse>, { id: string }, Error>()

export const deleteCleanTime =
  (cleanTimeId: string, isCleanupEnabled: boolean = false) =>
  async (dispatch, getState) => {
    const timer = getMergedCleanTimeById(getState(), cleanTimeId)

    // If the timer has a started offline flag, it hasn't been synced to the API, so we can just delete it locally
    if (timer?.startedOffline) {
      await offlineTimers.removeCleanTime(cleanTimeId)
      dispatch(deleteCleanTimeAction.success({ id: cleanTimeId }))
      return
    }

    try {
      const request = cleanTimesService.deleteCleanTime.bind(null, cleanTimeId)
      const result = await dispatch(deleteCleanTimeAction.request({ request }))

      // remove any existing copies of this timer from local cache
      await offlineTimers.removeCleanTime(cleanTimeId)

      dispatch(deleteCleanTimeAction.success({ id: cleanTimeId }))

      return result.normalized
    } catch (error) {
      // Clean up local storage for any 4xx errors when feature flag is enabled
      if (isCleanupEnabled && error?.status >= 400 && error?.status < 500) {
        await offlineTimers.removeCleanTime(cleanTimeId)
        dispatch(deleteCleanTimeAction.success({ id: cleanTimeId }))
        return
      }

      // For other errors, dispatch failure and throw
      dispatch(deleteCleanTimeAction.failure(error))
      throw error
    }
  }
