import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import { isIOS } from 'mobile-device-detect'
import React from 'react'

import {
  Alert,
  Button,
  InputField,
  Loader,
  Select,
  Switch,
} from 'packages/common'
import { StringSelect } from 'packages/common/src/Select/SimpleSelects'
import { InspectionCategoryTitle } from 'packages/grimoire/src/task'
import { IconName, SvgIcon } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { colors } from 'packages/styles'

import { UnitSearchSelector } from 'app/fieldapp/components/core/components/UnitSearchSelector'
import { ACCEPTED_FILE_TYPES } from 'app/fieldapp/components/tickets/TicketAttachments/constants'
import { InspectionFlag } from 'app/fieldapp/store/inspectionFlags/inspectionFlags.types'
import {
  Disposition,
  severityList,
  SeverityType,
} from 'app/fieldapp/store/tickets'
import { CreateTicketDrawerUnit } from 'app/fieldapp/store/units'
import { SearchUnitsOptions } from 'app/fieldapp/store/units/actions/searchUnits'

import { CreateTicketValuesState } from '../createTicketState/createTicket.reducer'
import { FileUploader } from '../FileUploader/FileUploader'
import { FileUploaderItem } from '../FileUploader/FileUploaderItem'
import {
  CreateTicketFormTranslations,
  DEFAULT_DISPOSITION_DATA,
} from './CreateTicketForm.container'
import {
  InspectionFlagOption,
  InspectionFlagSelect,
} from './InspectionFlagSelect'

const DESCRIPTION_MAX_CHARS = 160
const ADDITIONAL_DETAILS_MAX_CHARS = 2000

const parseEventValue = fn => e => fn(e.target.value)

const searchUnitsOptions: SearchUnitsOptions = {
  allUnits: true,
}

const St = {
  AddAdditionalDetailsBtn: styled(Button)`
    padding: 0;
  `,
  Alert: styled(Alert)`
    margin: 16px 0 0 0;
  `,
  ButtonContainer: styled.div`
    grid-gap: 16px;
    padding: 16px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  CharCount: styled.span`
    color: ${colors.dusk};
    font-size: 14px;
  `,
  ErrorMessage: styled.div`
    color: ${colors.alert};
  `,
  FileIcon: styled(SvgIcon)`
    margin-right: 8px;
  `,
  FileNameWrapper: styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
  `,
  FlagIcon: styled(SvgIcon)`
    background-color: ${colors.awareness70};
    border-radius: 50%;
    height: 36px;
    margin-right: 10px;
    width: 36px;
    padding: 8px;
  `,
  Header: styled.div`
    background: ${colors.midnight10};
    color: ${colors.midnight};
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    padding: 48px 16px 20px 16px;
    text-align: center;
  `,
  InspectionFlag: styled.div`
    align-items: center;
    margin-top: 6px;
    display: flex;
  `,
  Label: styled.label`
    color: ${colors.dusk};
    display: block;
    font-size: 14px;
    font-weight: 800;
    padding-bottom: 8px;
    text-transform: uppercase;
  `,
  LoaderWrapper: styled.div`
    height: 32px;
    position: relative;
    margin-top: 32px;
  `,
  Required: styled.span`
    color: ${colors.dusk60};
  `,
  Section: styled.section`
    padding: 24px 16px 0px 16px;
  `,
  Select: styled.div`
    margin-top: 16px;
  `,
  StyledFileList: styled.div`
    margin-top: 10px;
  `,
  TextArea: styled.textarea`
    border-radius: 4px;
    padding: 8px 16px;
    border: 1px solid ${colors.midnight30};
    width: 100%;
  `,
  UnitSearchSelector: styled(UnitSearchSelector)`
    border-radius: 4px;
    border: 1px solid ${colors.midnight30};
    width: 100%;
  `,
}

export enum CreateTicketFormTestIds {
  cancel = 'CreateTicketForm__cancel',
  container = 'CreateTicketForm__container',
  dispositionLoader = 'CreateTicketForm__dispositionLoader',
  error = 'CreateTicketForm__error',
  submit = 'CreateTicketForm__submit',
  toggleAdditionalDetails = 'CreateTicketForm__toggleAdditionalDetails',
  toggleAssignToSelf = 'CreateTicketForm__toggleAssignToSelf',
}

export type CreateTicketFormProps = {
  assigneeOptionTranslations: {}
  assigneeOptions: string[]
  displayVisibilityToggle: boolean
  eventHandlers: {
    cancel: () => void
    clearError: () => void
    onInputChange: (
      section: keyof CreateTicketValuesState,
    ) => (val: string) => void
    /** This prop drives the "unit search" field.
     * If it exists, we display the unit selector and pass it new units, which will update "state.selectedUnit"
     * If it is undefined, we will not show a unit selector.  */
    onUnitChange?: (unit: CreateTicketDrawerUnit) => void
    submit: (e: React.FormEvent<HTMLFormElement>) => void
    toggleAssignToSelf: () => void
    toggleDetails: () => void
    toggleInspectionFlag: (
      options: InspectionFlagOption[],
      flag: { option: InspectionFlagOption },
    ) => void
  }
  inspectionFlags: InspectionFlag[]
  loadingHousekeepingManagers: boolean
  loadingInspectionFlags: boolean
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onRemoveFile: (index: number) => void
  selectedFiles: File[]
  state: {
    allowAssignToSelf: boolean
    assignToSelf: boolean
    assigneeId: string
    canSubmit: boolean
    descriptionCharCount: number
    disposition: Disposition | null
    dispositions: Disposition[] | null | 'error'
    error: boolean
    inspectionFlagIds: string[]
    isDetailsOpen: boolean
    isLoading: boolean
    /** If "eventHandlers.onUnitChange" does not exist, and this is undefined, this form will not submit */
    selectedUnit?: CreateTicketDrawerUnit
    severity: SeverityType | null
    titleCharCount: number
    visibility: string
  }
  strings: CreateTicketFormTranslations
  taskIsVisit: boolean
}

const visibilityValues = ['internal', 'owner'] as const

export const getInspectionFlagCategoryTitle = (categoryId: string): string => {
  if (categoryId.includes('bedroom')) {
    return InspectionCategoryTitle.bedroom + ' ' + categoryId.split('_')[1]
  }

  if (categoryId.includes('bathroom')) {
    return InspectionCategoryTitle.bathroom + ' ' + categoryId.split('_')[1]
  }

  return InspectionCategoryTitle[categoryId]
}

const selectedInspectionFlag = (flag?: InspectionFlag) => {
  if (!flag) return null
  const label = `From "${getInspectionFlagCategoryTitle(flag.categoryId)}"
  ${flag.notes !== null ? ` : ${flag.notes}` : ''}`

  return (
    <St.InspectionFlag>
      <St.FlagIcon icon={IconName.flag} size={20} />
      {label}
    </St.InspectionFlag>
  )
}

export const CreateTicketForm: React.FC<CreateTicketFormProps> = React.memo(
  ({
    assigneeOptions,
    assigneeOptionTranslations,
    displayVisibilityToggle,
    eventHandlers,
    inspectionFlags,
    loadingHousekeepingManagers,
    loadingInspectionFlags,
    onFileChange,
    onRemoveFile,
    state,
    selectedFiles,
    strings,
    taskIsVisit,
  }) => {
    // this fixes some strange behavior on iOS where we would not be able to scroll internally
    // in inputs that support it when the form is scrolled all the way down (textarea, Select)
    // this just scrolls the container up 1px, which seems to fix the issue. Just iOS things.
    const iosScrollFix = React.useCallback(() => {
      if (!isIOS) return

      const formEl = document.querySelector('#CreateTicketDrawer')
      if (formEl) {
        // iOS does not support 'window.innerHeight' properly, so we have to use this less common measurement for it
        if (window.screen.height + formEl.scrollTop >= formEl.scrollHeight) {
          formEl.scrollBy(0, -1)
        }
      }
    }, [])
    const [decision] = useDecision(Features.TICKET_ATTACHMENTS)
    const isTicketAttachmentsEnabled = decision.enabled

    return (
      <form
        data-testid={CreateTicketFormTestIds.container}
        onSubmit={eventHandlers.submit}
      >
        <St.Header>{strings.headerText}</St.Header>
        {eventHandlers.onUnitChange && (
          <St.Section>
            <St.Label>
              {strings.unit} <St.Required>{strings.required}</St.Required>
            </St.Label>

            <St.UnitSearchSelector
              onUnitChange={eventHandlers.onUnitChange}
              searchUnitsOptions={searchUnitsOptions}
              selectedUnit={state.selectedUnit}
            />
          </St.Section>
        )}
        <St.Section>
          <St.Label htmlFor="title">
            {strings.titleLabel} <St.Required>{strings.required}</St.Required>
          </St.Label>
          <InputField
            id="title"
            maxLength={DESCRIPTION_MAX_CHARS}
            onInputChange={eventHandlers.onInputChange('title')}
          />
          <St.CharCount>
            {state.titleCharCount}/{DESCRIPTION_MAX_CHARS}
          </St.CharCount>
        </St.Section>
        <St.Section>
          {state.isDetailsOpen ? (
            <>
              <St.Label htmlFor="additionalDetails">
                {strings.descriptionLabel}{' '}
                <St.Required>{strings.required}</St.Required>
              </St.Label>
              <St.TextArea
                id="additionalDetails"
                maxLength={ADDITIONAL_DETAILS_MAX_CHARS}
                onChange={parseEventValue(
                  eventHandlers.onInputChange('description'),
                )}
                onFocus={iosScrollFix}
                rows={6}
              />
              <St.CharCount>
                {state.descriptionCharCount}/{ADDITIONAL_DETAILS_MAX_CHARS}
              </St.CharCount>
              {taskIsVisit && (
                <>
                  <St.Select>
                    <St.Label htmlFor="flaggedItems">
                      {strings.flaggedItems}{' '}
                      <St.Required>({strings.optional})</St.Required>
                    </St.Label>
                    {loadingInspectionFlags && (
                      <St.LoaderWrapper>
                        <Loader />
                      </St.LoaderWrapper>
                    )}
                    {!loadingInspectionFlags && (
                      <InspectionFlagSelect
                        handleChange={eventHandlers.toggleInspectionFlag}
                        inspectionFlags={inspectionFlags}
                        selectedInspectionFlags={state.inspectionFlagIds}
                      />
                    )}
                  </St.Select>
                  {state.inspectionFlagIds.map(flagId =>
                    selectedInspectionFlag(
                      inspectionFlags.find(flag => flag.id === flagId),
                    ),
                  )}
                </>
              )}
              <St.Select>
                <St.Label htmlFor="assignmentPreference">
                  {strings.assignmentPreference}{' '}
                  <St.Required>{strings.required}</St.Required>
                </St.Label>
                {loadingHousekeepingManagers && (
                  <St.LoaderWrapper>
                    <Loader />
                  </St.LoaderWrapper>
                )}
                {!loadingHousekeepingManagers && (
                  <StringSelect
                    id="assignmentPreference"
                    onChange={eventHandlers.onInputChange('assigneeId')}
                    options={assigneeOptions}
                    maxMenuHeight={200}
                    translateValue={str => assigneeOptionTranslations[str]}
                    value={state.assigneeId}
                  />
                )}
              </St.Select>
            </>
          ) : (
            <St.AddAdditionalDetailsBtn
              dataTestId={CreateTicketFormTestIds.toggleAdditionalDetails}
              onClick={eventHandlers.toggleDetails}
              buttonType={'text'}
            >
              {strings.additionLabelsToggle}
            </St.AddAdditionalDetailsBtn>
          )}
        </St.Section>

        {displayVisibilityToggle && (
          <St.Section>
            <St.Label htmlFor="visibility">
              {strings.visibility} <St.Required>{strings.required}</St.Required>
            </St.Label>
            <StringSelect
              id="visibility"
              onChange={eventHandlers.onInputChange('visibility')}
              options={visibilityValues}
              maxMenuHeight={200}
              translateValue={str => strings.visibilityLabels[str]}
              value={state.visibility}
            />
          </St.Section>
        )}

        {state.allowAssignToSelf && !state.isDetailsOpen && (
          <St.Section>
            <St.Label htmlFor="assignToMyself">
              {strings.assignToMyselfLabel}
            </St.Label>
            <Switch
              checked={state.assignToSelf}
              id="assignToMyself"
              dataTestId={CreateTicketFormTestIds.toggleAssignToSelf}
              onToggle={eventHandlers.toggleAssignToSelf}
            />
          </St.Section>
        )}

        <St.Section>
          <St.Label htmlFor="dispositionId">
            {strings.dispositionIdLabel}{' '}
            <St.Required>{strings.required}</St.Required>
          </St.Label>

          {state.dispositions ? (
            <>
              <div data-testid="disposition-select">
                <Select
                  disabled={state.dispositions === 'error'}
                  id="dispositionId"
                  onChange={eventHandlers.onInputChange('disposition')}
                  getOptionLabel={(disposition: Disposition) =>
                    disposition.tier3Display
                  }
                  maxMenuHeight={200}
                  onFocus={iosScrollFix}
                  placeholder={
                    state.dispositions === 'error'
                      ? strings.defaultDispositionDisplayValue
                      : undefined
                  }
                  options={
                    state.dispositions === 'error'
                      ? [DEFAULT_DISPOSITION_DATA]
                      : Object.values(state.dispositions)
                  }
                  selectedValue={state.disposition}
                />
              </div>
              {state.dispositions === 'error' && (
                <St.ErrorMessage>
                  {strings.dispositionsFailToLoadMessage}
                </St.ErrorMessage>
              )}
            </>
          ) : (
            <St.LoaderWrapper
              data-testid={CreateTicketFormTestIds.dispositionLoader}
            >
              <Loader />
            </St.LoaderWrapper>
          )}
        </St.Section>

        <St.Section>
          <St.Label htmlFor="severity">
            {strings.severityLabel}{' '}
            <St.Required>{strings.required}</St.Required>
          </St.Label>
          <div data-testid="severity-select">
            <StringSelect
              id="severity"
              onChange={eventHandlers.onInputChange('severity')}
              options={severityList}
              maxMenuHeight={200}
              translateValue={str => strings.severityLevels[str]}
              value={state.severity}
            />
          </div>
        </St.Section>
        {isTicketAttachmentsEnabled && (
          <St.Section>
            <St.Label htmlFor="fileUpload">
              {strings.uploadFiles}{' '}
              <St.Required>({strings.optional})</St.Required>
            </St.Label>
            <FileUploader
              id="fileUpload"
              accept={ACCEPTED_FILE_TYPES}
              onChange={onFileChange}
              title={strings.uploadFiles}
            />
            {selectedFiles.length > 0 && (
              <St.StyledFileList>
                {selectedFiles.map((file, index) => (
                  <FileUploaderItem
                    key={index}
                    file={file}
                    onRemove={() => onRemoveFile(index)}
                  />
                ))}
              </St.StyledFileList>
            )}
          </St.Section>
        )}

        {state.error && (
          <St.Alert
            alertType={'danger'}
            dataTestId={CreateTicketFormTestIds.error}
            onClose={eventHandlers.clearError}
          >
            {strings.failureMessage}
          </St.Alert>
        )}
        <St.ButtonContainer>
          <Button
            block={true}
            dataTestId={CreateTicketFormTestIds.cancel}
            onClick={eventHandlers.cancel}
            buttonType={'utility'}
          >
            {strings.cancel}
          </Button>
          <Button
            block={true}
            dataTestId={CreateTicketFormTestIds.submit}
            disabled={!state.canSubmit}
            isLoading={state.isLoading}
            buttonType={'primary'}
            isFormSubmit={true}
          >
            {strings.submit}
          </Button>
        </St.ButtonContainer>
      </form>
    )
  },
)
