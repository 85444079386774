import Axios, { AxiosInstance } from 'axios'
import jsonApiNormalizer from 'json-api-normalizer'

import { parseQuery } from 'packages/utils/store/jsonapi.utils'

const ticketingHost: string = process.env.REACT_APP_TICKETING_API || ''

export const api: AxiosInstance = Axios.create({
  baseURL: ticketingHost,
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
  paramsSerializer: { serialize: params => parseQuery(params) },
  timeout: 30000,
})

export const setRequestHeader = (name: string, value: string): void => {
  api.defaults.headers[name] = value
}

/**********************************************************
 * Configure interceptor for normalizing JSON API data
 *********************************************************/
const normalizeJsonApiData = response => {
  response.data = {
    normalized:
      jsonApiNormalizer(response.data, { camelizeTypeValues: false }) || {},
    raw: response.data,
  }

  return response
}

api.interceptors.response.use(normalizeJsonApiData, error =>
  Promise.reject(error),
)
